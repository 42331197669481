import { Box, Button, Text } from "@chakra-ui/react";
import { FaRegSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import LoadingView from "../../Common/LoadingView";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import Tool from "../../../../domain/entities/tool";
import { COLORS } from "../../../assets/theme/colors";
import DataBox from "../../../components/Common/DataBox";
import { useNavigate, useParams } from "react-router-dom";
import ContentLayout from "../../../layout/ContentLayout";
import { MdCancel, MdClose, MdEdit } from "react-icons/md";
import ActionBar from "../../../components/Common/ActionBar";
import ResourceDocumentsView, {
  ResourceDocumentProps,
} from "../ResourceDocumentsView";
import RenderIf from "../../../components/Permissions/RenderIf";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import useToolViewModel from "../../../hooks/Resources/Tools/useToolViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import useToolDocumentsViewModel from "../../../hooks/Resources/Tools/useToolDocumentsViewModel";
import { useDocumentFilesDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import DocumentCommunicationModal from "../../../components/Views/common/DocumentCommunicationModal";
import BaseModal from "../../../components/Common/alerts/BaseModal";
import SelectSitesView from "../../../components/Views/common/SelectSitesView";
import DOMPurify from "dompurify";
import { FaUserPlus, FaWandMagicSparkles } from "react-icons/fa6";
import { AiTaskStatus } from "../../../../domain/entities/aiTask";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";
import { Alert } from "../../Common/Alert";
import { LuPackagePlus } from "react-icons/lu";

interface Props {
  permissions: ResourceDocumentProps["permissions"] & {
    editRecord: Permission;
    manageDocumentWithAi: Permission;
  };
}

const ToolDetailView: FC<Props> = ({ permissions }) => {
  const { id } = useParams();
  const { t } = useTranslation("tools");
  const {
    tool,
    isFetching,
    updateImage,
    updateTool,
    resourceSites,
    filterResourceSites,
    updateFilterResourceSites,
    setSortResourceSites,
    sortResourceSites,
    isFetchingSites,
    hasNextPage,
    fetchNextPage,
    setEnableGetResourceSites,
    hasSites,
    siteCollection,
    sortSiteCollection,
    setSortSiteCollection,
    filterSiteCollection,
    setFilterSiteCollection,
    updateFilterSiteCollection,
    siteCollectionHasNextPage,
    totalSiteCount,
    siteCollectionFetchNextPage,
    refetchSiteCollection,
    linkToolToSites,
    isLinking,
    selectAll,
    setSelectAll,
    showInfoSuccess,
    setShowInfoSuccess,
    setFilterResourceSites,
    resourceLinkableSitesCount
  } = useToolViewModel(id as string);
  const [showSiteCollection, setShowSiteCollection] = useState(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>();
  const documentsHook = useToolDocumentsViewModel(id);
  const [showAddDocumentType, setShowAddDocumentType] =
    useState<boolean>(false);
  const downloader = useDocumentFilesDownloader();
  const [init, setInit] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [showManageTaskWithAI, setShowManageTaskWithAI] =
    useState<boolean>(false);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const sanitizedHtmlContent = DOMPurify.sanitize(
    t("addResourceToSites", { ns: "sites", resource: `${tool?.name}` })
  );

  const navigate = useNavigate();
  const goToToolsList = () => {
    navigate(`/resources/tools`);
  };

  const onSubmit: SubmitHandler<Tool> = (data) => {
    if (uploadedImageFile) {
      updateImage(
        { ...tool, name: data.name, serial: data.serial },
        uploadedImageFile
      );
    } else {
      updateTool({ ...tool, name: data.name, serial: data.serial }).then(() => {
        setIsEditing(false);
      });
    }
    setIsEditing(false);
  };

  const methods = useForm<Tool>({ mode: "all", defaultValues: tool });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (!tool || init) {
      return;
    }

    reset({ name: tool.name, serial: tool.serial });
    setInit(true);
  }, [tool, init, isEditing, reset]);

  const refetchCommentsAndNotes = () => {
    documentsHook.communicationProps.refetchResourceDocumentComments(),
      documentsHook.communicationProps.refetchResourceDocumentNotes();
  };

  if (!tool) {
    return <LoadingView />;
  }

  const handleLinkToSites = async () => {
    await linkToolToSites(selectAll ? [] : selectedSiteIds, selectAll);
    setFilterSiteCollection(null);
    setShowSiteCollection(false);
    setSelectedSiteIds([]);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={goToToolsList}
          />

          <RenderIf permissions={permissions.editRecord}>
            <ActionBarItem
              icon={isEditing ? MdCancel : MdEdit}
              onClick={() => {
                setIsEditing(!isEditing);
                if (isEditing) {
                  setUploadedImageFile(undefined);
                }
              }}
              description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
            />
          </RenderIf>
          {isEditing && isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={handleSubmit(onSubmit)}
            />
          )}
          <RenderIf permissions={permissions.documents.chat}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes(), setShowCommunicationModal(true);
              }}
            />
          </RenderIf>
          <ActionBarItem
            bgColor={COLORS.yellow}
            color="black"
            icon={LuPackagePlus }
            description={t("addResourceToSitesTooltip", {
              ns: "sites",
              resource: `${tool?.name}`,
            })}
            onClick={() => {
              refetchSiteCollection(), setShowSiteCollection(true);
            }}
          />
        </ActionBar>
      }
    >
      {tool && (
        <Box px={3} py={5} marginLeft={7}>
          <Box textStyle="h1" mb={2}>
            {tool?.name}
          </Box>
          <Box
            pb={8}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
          >
            <FormProvider {...methods}>
              <DataBox
                title={t("details", { ns: "common" })}
                isEditing={isEditing}
                isLoading={isFetching}
                image={{
                  url: uploadedImageFile
                    ? URL.createObjectURL(uploadedImageFile)
                    : tool.photo,
                  onUpdate: (f) => setUploadedImageFile(f),
                }}
                fields={[
                  <FormTextField
                    key="name"
                    name="name"
                    label={t("name")}
                    rules={requiredRule}
                  />,
                  <FormTextField
                    key="serial"
                    name="serial"
                    label={t("serial")}
                    rules={requiredRule}
                  />,
                ]}
              />
            </FormProvider>
          </Box>
          <ResourceDocumentsView
            category={DocumentTypeCategory.TOOL}
            documentsHook={documentsHook}
            resourcePath="/resources/tools"
            showAddDocumentType={showAddDocumentType}
            setShowAddDocumentType={setShowAddDocumentType}
            downloader={downloader}
            resourceSites={resourceSites}
            hasSites={hasSites}
            isFetchingSites={isFetchingSites}
            setEnableGetResourceSites={setEnableGetResourceSites}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            setSelectAllSites={setSelectAll}
            selectAllSites={selectAll}
            siteCount={totalSiteCount}
            filterResourceSites={filterResourceSites}
            updateFilterResourceSites={updateFilterResourceSites}
            setSortResourceSites={setSortResourceSites}
            sortResourceSites={sortResourceSites}
            permissions={permissions}
            showManageTaskWithAI={showManageTaskWithAI}
            setShowManageTaskWithAI={setShowManageTaskWithAI}
            resourceId={tool.id}
            setFilterResourceSites={setFilterResourceSites}
          />
        </Box>
      )}
      {showCommunicationModal && (
        <DocumentCommunicationModal
          onClose={() => setShowCommunicationModal(false)}
          hook={documentsHook.communicationProps}
          permissions={permissions.documents}
        />
      )}
      {showSiteCollection && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setShowSiteCollection(false)}
          footer={[
            <Button
              key="confirm"
              isDisabled={!selectedSiteIds || selectedSiteIds?.length === 0}
              colorScheme="blue"
              onClick={() => handleLinkToSites()}
              isLoading={isLinking}
            >
              {t("confirm", { ns: "common" })}
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => {
                setFilterSiteCollection(null);
                setShowSiteCollection(false);
              }}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <Text
            fontSize={16}
            marginTop={3}
            marginBottom={5}
            dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
          />
          <SelectSitesView
            autosize
            title={""}
            siteSelectedAction={setSelectedSiteIds}
            siteList={siteCollection}
            setSortResourceSites={setSortSiteCollection}
            sortResourceSites={sortSiteCollection}
            filterResourceSites={filterSiteCollection}
            updateFilterResourceSites={updateFilterSiteCollection}
            hasNextPage={siteCollectionHasNextPage}
            fetchNextPage={() => siteCollectionFetchNextPage()}
            includeFooterButton={false}
            setSelectAllSites={setSelectAll}
            selectAllSites={selectAll}
            siteCount={resourceLinkableSitesCount}
            showSelectAll={true}
            hideSubtitles={true}
          />
        </BaseModal>
      )}
      {showInfoSuccess && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(showInfoSuccess, { ns: "siteResources"})}
          variant="info"
          onClose={() => {
            setShowInfoSuccess(null);
          }}
        />
      )}
    </ContentLayout>
  );
};

export default ToolDetailView;
