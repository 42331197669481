import { useApp } from "../../../app";
import {
  Box,
  Checkbox,
  Flex,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import SiteTopBarName from "./SiteTopBarName";
import { useTranslation } from "react-i18next";
import LoadingView from "../Common/LoadingView";
import DataBox from "../../components/Common/DataBox";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import ContentLayout from "../../layout/ContentLayout";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import StatusToggle from "../../components/Common/StatusToggle";
import Site, { SiteState } from "../../../domain/entities/site";
import Staff, { StaffType } from "../../../domain/entities/staff";
import { Permission } from "../../components/Permissions/Permissions";
import CompanyDetailActionBar from "../Company/CompanyDetailActionBar";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import FormTextField from "../../components/Common/forms/FormTextField";
import { useHasPermissions } from "../../components/Permissions/RenderIf";
import StaffTableView from "../../components/Views/Company/StaffTableView";
import DocumentsTable from "../../components/Views/Documents/DocumentsTable";
import useSiteDetailsViewModel from "../../hooks/Site/useSiteDetailsViewModel";
import CreateUpdateStaffModal from "../../components/Views/Staff/CreateStaffModal";
import useDocumentsListViewModel from "../../hooks/Document/useDocumentsViewModel";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { useSupplierOrSiteFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { SiteGeneralDocumentsViewModel } from "../../viewmodels/documents/SiteGeneralDocumentsViewModel";
import { ResourceDocumentsPermissions } from "../../components/Views/common/SiteResourceDocuments";
import FormDateField from "../../components/Common/forms/FormDateField";
import useSiteOptionsViewModel from "../../hooks/Site/useSiteOptionsViewModel";
import useSiteRequirementsViewModel from "../../hooks/Site/useSiteRequirementsViewModel";
import Requirement from "../../../domain/entities/requirement";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { ImSpinner6 } from "react-icons/im";
import { BlobProvider } from "@react-pdf/renderer";
import RequirementsPdf from "../../components/Common/RequirementsPdf";
import { COLORS } from "../../assets/theme/colors";
import { MdCloudDownload } from "react-icons/md";

type SiteDetailsPermissions = {
  documents: ResourceDocumentsPermissions;
  addDocument: Permission;
  downloadDocuments: Permission;
};

const SiteDetailsView: FC<{
  readonly: boolean;
  showStatus: boolean;
  permissions: SiteDetailsPermissions;
  isWorkingSite: boolean;
}> = ({ readonly, showStatus, permissions, isWorkingSite }) => {

  const { siteId } = useParams();
  const { t } = useTranslation("sites");
  const [init, setInit] = useState(false);
  const { updateContext } = useApp();
  const [isEditing, setIsEditing] = useState(false);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [pdfPopoverIsOpen, setPdfPopoverIsOpen] = useState(false);
  const [resourceType, setResourceType] = useState<RequirementSubject>();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const [requirementsByResource, setRequirementsByResource] = useState<Record<string, Requirement[]>>({});
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();
  const [disableDownloadPdf, setDisableDownloadPdf] = useState<boolean>(true);
  const [checkedItems, setCheckedItems] = useState({
    worker: false,
    chemical: false,
    vehicle: false,
    tool: false,
    site: false,
    supplier: false,
  });
  const [link, setLink] = useState(null);

  const {
    site,
    isFetching,
    updateSite,
    updateSiteImage,
    updateState,
    staff,
    staffIsFetching,
    updateStaffIsLoading,
    createStaffIsLoading,
    createStaff,
    updateFilter,
    staffFilter,
    staffSort,
    setStaffSort,
    deleteStaff,
    updateStaff,
    deleteStaffIsLoading,
    coordinates,
  } = useSiteDetailsViewModel(siteId);

  const siteRequirementHook = useSiteRequirementsViewModel(
    (resourceType as RequirementSubject) || RequirementSubject.SITE
  );

  const { requirements, requirementsLoading } = siteRequirementHook(
    siteId,
    resourceType as RequirementSubject
  );
  const viewModel = new SiteGeneralDocumentsViewModel(siteId);
  const downloader = useSupplierOrSiteFilesDownloader(siteId, "site");

  const handleSelection = (
    e: ChangeEvent<HTMLInputElement>,
    value: RequirementSubject
  ) => {
    setCheckedItems((prev) => ({
      ...prev,
      [value]: e.target.checked,
    }));
    if (e.target.checked && value) {
      setResourceType(value);
    } else if (
      Object.keys(requirementsByResource).includes(value) &&
      !e.target.checked
    ) {
      delete requirementsByResource[value];
    }
  };

  const menuItems = [
    {
      label: t("sub.workers", { ns: "navbar" }),
      key: "worker",
    },
    {
      label: t("sub.vehicles", { ns: "navbar" }),
      key: "vehicle",
    },
    {
      label: t("sub.machines", { ns: "navbar" }),
      key: "machine",
    },
    {
      label: t("sub.tools", { ns: "navbar" }),
      key: "tool",
    },
    {
      label: t("sub.chemicals", { ns: "navbar" }),
      key: "chemical",
    },
    {
      label: t("sub.suppliers", { ns: "navbar" }),
      key: "supplier",
    }
  ];

  let results: Requirement[] = [];
  for (const [key, value] of Object.entries(requirementsByResource)) {
    results = [...results, ...value];
  }

  const {
    documentsProps: { createDocument, deleteDocument, ...docsProps },
    documentTypesProps,
    documentProps,
  } = useDocumentsListViewModel(
    viewModel,
    DocumentTypeCategory.SITE,
    "site-general-documents",
    siteId
  );

  const [confirmChangeStateModal, setConfirmChangeStateModal] = useState<SiteState | false>(false);

  const { options } = useSiteOptionsViewModel(siteId, isWorkingSite);

  options &&
    localStorage.setItem(
      "isReviewEnabled",
      String(options.isEvaluationApprovalRequired)
    );

  const onSubmit: SubmitHandler<Site> = (data) => {
    if (uploadedImageFile) {
      updateSiteImage(
        {
          id: site.id,
          protocol: data.protocol,
          name: data.name,
          description: data.description,
          contract: data.contract,
          startDate: data.startDate,
          lifespan: data.lifespan,
          address: data.address,
          city: data.city,
          zipCode: data.zipCode,
          state: site.state,
        },
        uploadedImageFile
      );
    } else {
      updateSite({
        id: site.id,
        protocol: data.protocol,
        name: data.name,
        description: data.description,
        contract: data.contract,
        startDate: data.startDate,
        lifespan: data.lifespan,
        address: data.address,
        city: data.city,
        zipCode: data.zipCode,
        state: site.state,
      });
    }
    setIsEditing(false);
  };

  const resetSite = () => {
    reset({
      protocol: site.protocol,
      name: site.name,
      description: site.description ?? "",
      contract: site.contract ?? "",
      startDate: site.startDate,
      lifespan: site.lifespan,
      address: site.address ?? "",
      city: site.city ?? "",
      zipCode: site.zipCode ?? "",
    });
  };

  const toggleIsEditing = (isEditing: boolean) => {
    if (!isEditing) {
      setUploadedImageFile(undefined);
      resetSite();
    }
    setIsEditing(isEditing);
  };

  const methods = useForm<Site>({
    mode: "all",
    defaultValues: site,
  });
  const {
    handleSubmit,
    reset,
    formState: { isValid }
  } = methods;

  const createStaffAction = async (staffElement: Staff) => {
    await createStaff(staffElement);
    setShowCreate(false);
  };

  useEffect(() => {
    if (
      resourceType &&
      !Object.keys(requirementsByResource).includes(resourceType) &&
      requirements?.length > 0
    ) {
      setRequirementsByResource((prevState) => {
        return {
          ...prevState,
          [resourceType]: requirements,
        };
      });
    }
  }, [requirements]);

  useEffect(() => {
    if (pdfPopoverIsOpen) {
      setCheckedItems({
        worker: false,
        chemical: false,
        vehicle: false,
        tool: false,
        site: false,
        supplier: false,
      });
      setResourceType(null);
    }
  }, [pdfPopoverIsOpen]);

  useEffect(() => {
    updateContext({
      topBarTitle: <SiteTopBarName site={site} stateVisible={true} />,
    });

    if (!site || init) {
      return;
    }

    resetSite();
    setInit(true);
  }, [site, init, isEditing, reset]);

  useEffect(() => {
    checkedItems && setDisableDownloadPdf(Object.values(checkedItems).some(value => value === true))
  }, [checkedItems]);

  useEffect(() => {
    if (site?.address && site?.city && site?.zipCode) {
      setLink(
        `https://www.google.com/maps/place/${coordinates?.lat},${coordinates?.lng}`
      );
    }
  }, [coordinates]);

  const canSetStatus = useHasPermissions([
    Permission.Sites_Manage,
    Permission.Sites_SetStatus,
  ]);

  if (!site) {
    // Company data not yet available.
    return <LoadingView />;
  }

  const requiredRule = {
    required: t("requiredField", { ns: "common" }),
  };

  return (
    <ContentLayout
      action={
        <>
          {!readonly ? (
            <Flex flexDirection={"column"} alignItems={"center"}>
              <CompanyDetailActionBar
                onAdd={() => setShowCreate(true)}
                isEditing={isEditing}
                canSave={isEditing && isValid}
                onSave={handleSubmit(onSubmit)}
                onToggleEdit={isFetching ? undefined : toggleIsEditing}
                editDetailsPermissions={[
                  Permission.Sites_Manage,
                  Permission.Sites_EditRecords,
                ]}
                editStaffPermissions={[
                  Permission.Sites_Manage,
                  Permission.Sites_EditRoles,
                ]}
              />
            </Flex>
          ) : null}
          <Flex flexDirection={"column"} alignItems={"center"}>
            <Popover isOpen={pdfPopoverIsOpen}>
              <PopoverTrigger>
                <ActionBarItem
                  icon={MdCloudDownload}
                  description={t("pdf.tooltipExportPdf", {
                    ns: "common",
                  })}
                  onClick={() => (
                    setPdfPopoverIsOpen(true), setRequirementsByResource({})
                  )}
                />
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton
                    cursor={"pointer"}
                    onClick={() => (setPdfPopoverIsOpen(false), setRequirementsByResource({}))}
                  />
                  <PopoverBody>
                    <Flex flexDirection={"column"} gap={2} my={4}>
                      {menuItems.map((menuItem) => {
                        return (
                          <Checkbox
                            isChecked={checkedItems[menuItem.key]}
                            isDisabled={requirementsLoading}
                            key={menuItem.key}
                            onChange={(e) => {
                              handleSelection(
                                e,
                                menuItem.key as RequirementSubject
                              );
                            }}
                          >
                            {menuItem.label}{" "}
                          </Checkbox>
                        );
                      })}
                    </Flex>
                  </PopoverBody>

                  <PopoverFooter display={"flex"} justifyContent={"center"}>
                    <Tooltip
                      shouldWrapChildren
                      label={disableDownloadPdf ? t("pdf.tooltipExportPdf", {
                        ns: "common",
                      }): t("pdf.selectResourceType", {
                        ns: "common",
                      }) }
                    >
                      <BlobProvider
                        document={
                          <RequirementsPdf
                            data={results}
                            columnsVariantTable={[
                              t("pdf.variants", {
                                ns: "common",
                              }),
                              t("pdf.documents", {
                                ns: "common",
                              }),
                            ]}
                            columnsSpecializationTable={[
                              t("pdf.specializations", {
                                ns: "common",
                              }),
                              t("pdf.documents", {
                                ns: "common",
                              }),
                            ]}
                            title={t("pdf.tooltipExportPdf", {
                              ns: "common",
                            })}
                            subTitle={t("pdf.subTitle", {
                              ns: "common",
                            })}
                          />
                        }
                      >
                        {({ url, loading }) => (
                          <Box
                            backgroundColor={disableDownloadPdf ? COLORS.sikuroBlue : COLORS.gray}
                            width={"18rem"}
                            color={COLORS.white}
                            padding={2}
                            borderRadius={"5px"}
                          >
                            <Link
                              onClick={(e) => {
                                setPdfPopoverIsOpen(false);
                                setRequirementsByResource({})
                              }}
                              href={disableDownloadPdf ? url : null}
                              _hover={{
                                textDecoration: "none",
                                cursor: disableDownloadPdf ? "pointer"  : "not-allowed"
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Flex
                                gap={4}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                {!requirementsLoading ? (
                                  <>
                                    {t("pdf.download", {
                                      ns: "common",
                                    })}
                                    {loading ? (
                                      <ImSpinner6 />
                                    ) : (
                                      <FaCloudDownloadAlt />
                                    )}
                                  </>
                                ) : (
                                  <Spinner />
                                )}
                              </Flex>
                            </Link>
                          </Box>
                        )}
                      </BlobProvider>
                    </Tooltip>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </Popover>
          </Flex>
        </>
      }
    >
      {site && (
        <FormProvider {...methods}>
          <Flex px={3} py={5} gap="20px" flexWrap={"wrap"}>
            <Box
              p="10px 20px"
              gap="10px"
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
              flex="1 1 900px"
            >
              <DataBox
                image={{
                  url: uploadedImageFile
                    ? URL.createObjectURL(uploadedImageFile)
                    : site.photo,
                  onUpdate: (f) => setUploadedImageFile(f),
                }}
                title={t("summary")}
                isEditing={isEditing}
                isLoading={isFetching}
                fields={[
                  <FormTextField
                    key="protocol"
                    name="protocol"
                    label={t("protocol")}
                  />,
                  <FormTextField key="name" name="name" label={t("name")} />,
                  <FormTextField
                    key="description"
                    name="description"
                    label={t("description")}
                  />,
                  <FormTextField
                    key="contract"
                    name="contract"
                    label={t("contract")}
                  />,
                  <FormDateField
                    key="startDate"
                    name="startDate"
                    label={t("startDate")}
                    rules={requiredRule}
                  />,
                  <FormTextField
                    key="lifespan"
                    name="lifespan"
                    label={t("lifespan")}
                    type="number"
                  />,
                  <FormTextField
                    key="address"
                    name="address"
                    label={t("address")}
                    link={link}
                  />,
                  <FormTextField key="city" name="city" label={t("city")} />,
                  <FormTextField
                    key="zipCode"
                    name="zipCode"
                    label={t("zipCode")}
                  />,
                ]}
              />
            </Box>
            {showStatus && (
              <Flex
                p="10px 20px"
                flexDirection="column"
                gap="10px"
                width="308px"
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
                flex="1 1 300px"
              >
                <Box textStyle="h2">{t("state")}</Box>
                <StatusToggle
                  status={site.state}
                  onChange={(status) =>
                    setConfirmChangeStateModal(status as SiteState)
                  }
                  type="site"
                  disabled={!canSetStatus}
                  isTablet={isTablet}
                />
              </Flex>
            )}
          </Flex>

          <Tabs
            width={"100%"}
            px={4}
          >
            <TabList>
              {readonly && <Tab>{t("siteGeneralDocuments")}</Tab>}
              <Tab>{t("siteFigures")}</Tab>
            </TabList>
            <TabPanels>
              {readonly && (
                <TabPanel px={0}>
                  <Flex
                    marginTop={15}
                    flexDirection={"column"}
                    alignItems={"start"}
                    flex={1}
                    position="relative"
                    overflow={"hidden"}
                  >
                    <DocumentsTable
                      {...docsProps}
                      {...documentProps}
                      downloader={downloader}
                      columns={[
                        {
                          field: "name",
                          filterType: "text" as const,
                          label: t("name"),
                        },
                      ]}
                      tags={documentTypesProps.allTags}
                      deleteDocument={deleteDocument}
                      isReadOnly={true}
                      showExpiresAt={false}
                      fromSite={false}
                      isWorkingSite={isWorkingSite}
                      siteGeneralDocument={true}
                      permissions={permissions.documents}
                    />
                  </Flex>
                </TabPanel>
              )}
              <TabPanel px={0}>
                <StaffTableView
                  staffIsLoading={updateStaffIsLoading}
                  deleteStaffIsLoading={deleteStaffIsLoading}
                  staffType={StaffType.SITE_CUSTOM}
                  staffs={staff}
                  isFetching={staffIsFetching}
                  sorting={staffSort}
                  filters={staffFilter}
                  updateStaffsSorting={setStaffSort}
                  updateStaffsFilter={updateFilter}
                  updateStaffElement={updateStaff}
                  deleteStaffElement={deleteStaff}
                  canEditPermissions={[
                    Permission.Sites_Manage,
                    Permission.Sites_EditRoles,
                  ]}
                  from={"site"}
                  isTablet={isTablet}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </FormProvider>
      )}

      {showCreate && (
        <CreateUpdateStaffModal
          createUpdateStaffIsLoading={
            createStaffIsLoading || updateStaffIsLoading
          }
          onCancel={() => {
            setShowCreate(false);
          }}
          onConfirm={createStaffAction}
          staffType={StaffType.SITE_CUSTOM}
          title={t("createStaff", {
            ns: "staff",
          })}
        />
      )}

      {confirmChangeStateModal && (
        <ConfirmAlert
          onCancel={() => {
            setConfirmChangeStateModal(false);
          }}
          onConfirm={async () => {
            updateState(confirmChangeStateModal as SiteState);
            setConfirmChangeStateModal(false);
          }}
          title={t("updateMessage")}
          variant="warning"
        />
      )}
    </ContentLayout>
  );
};

export default SiteDetailsView;
