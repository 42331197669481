import React from "react";
import Badge from "../../../../domain/entities/badge";
import { styles } from "./badgeStyles";
import { Image, Text, View } from "@react-pdf/renderer";
import QRCode from "qrcode";

type QrCodeBadgeBackProps = {
  badge: Badge;
};

const QrCodeBadgeBack = ({ badge: { site: {company, protocol, photo}, serial } }: QrCodeBadgeBackProps) => {
  const qrCodeDataURL = QRCode.toDataURL(serial);
  return (
    <View style={styles.badgeContainer}>
      <View style={{ flexDirection: "row", marginBottom: "1mm" }}>
        <Text style={{ fontWeight: 700 }}>Appalto N: </Text>
        <Text>{protocol}</Text>
      </View>
      <View style={{ flexDirection: "row", marginBottom: "3mm" }}>
        <Text style={{ fontWeight: 700 }}>Committente: </Text>
        <Text>{company}</Text>
      </View>
        <View style={photo ? styles.qrCodeContainer : styles.qrCodeOnlyContainer}>
          {photo &&  <Image style={styles.siteImage} src={photo} />}
          <Image style={styles.photo} src={qrCodeDataURL} />
        </View>
    </View>
  );
};

export default QrCodeBadgeBack;
