import React, { FC, useState } from "react";
import TagList from "../Common/TagList";
import { GrChapterAdd } from "react-icons/gr";
import { Trans, useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import { useNavigate, useParams } from "react-router-dom";
import StatusBadge from "../../components/Common/StatusBadge";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { BadgeType } from "../../../domain/entities/badgeType.enum";
import DeleteButton from "../../components/Common/table/DeleteButton";
import { Permission } from "../../components/Permissions/Permissions";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import { BadgeStatus } from "../../../domain/entities/badgeStatus.enum";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import RenderIf from "../../components/Permissions/RenderIf";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import { GetSiteBadgesFilters } from "../../../domain/repositories/badgeRepository";
import AddBadgesToSiteModal from "../../components/Views/Badge/AddBadgesToSiteModal";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import { useSiteBadgesListViewModel } from "../../hooks/Badge/useSiteBadgesListViewModel";
import {
  Button,
  Checkbox,
  Flex,
  Link,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import LinkBadgesToSiteResourcesModal from "../../components/Views/Badge/LinkBadgesToSiteResourcesModal";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import ImportLinkResourceBadgeModal from "../../components/Views/Badge/ImportLinkResourceBadgeModal";
import { MdDelete } from "react-icons/md";
import BaseModal from "../../components/Common/alerts/BaseModal";
import SiteBadgesPrint from "./SiteBadgesPrint";

interface Props {
  viewDetailsPermissions: Permission[];
  managePermissions: Permission[];
}

const SiteBadgesView: FC<Props> = ({ managePermissions }) => {
  const { siteId } = useParams();
  const { t } = useTranslation("badges");
  const navigate = useNavigate();

  const {
    siteBadges,
    totalBadgeRegisteredCount,
    siteBadgesHasNextPage,
    siteBadgesFetchNextPage,
    siteBadgesIsLoading,
    siteBadgesFilters,
    updateSiteBadgesFilter,
    siteBadgesSort,
    setSiteBadgesSort,
    siteAvailableBadgesTotalCount,
    siteBadgesTags,
    availableBadges,
    availableBadgesTags,
    getAvailableBadges,
    availableBadgeSitesIsLoading,
    availableBadgesHasNextPage,
    availableBadgesFetchNextPage,
    availableBadgesFilters,
    updateAvailableBadgesFilter,
    availableBadgesSort,
    setAvailableBadgesSort,
    availableSiteBadges,
    availableSiteBadgesCount,
    availableSiteBadgesTags,
    availableSiteBadgesHasNextPage,
    availableSiteBadgesFetchNextPage,
    availableSiteBadgesFilters,
    updateAvailableSiteBadgesFilter,
    availableSiteBadgesSort,
    setAvailableSiteBadgesSort,
    linkBadgesToSiteResources,
    linkingIsLoading,
    linkBadgesToSite,
    linkBadgesToSiteIsLoading,
    unlinkBadgeFromSite,
    setBadgesInterval,
    badgesInterval,
    siteResources,
    siteResourcesIsLoading,
    siteResourcesCount,
    refetchBadgeSiteFromInterval,
    setResourceType,
    resourceType,
    siteResourcesFilters,
    updateSiteResourcesFilter,
    siteResourcesSort,
    setSiteResourcesSort,
    siteResourcesFetchNextPage,
    siteResourcesHasNextPage,
    badgeSites,
    setBadgeId,
    importLinkResourcesBadges,
    badgeSiteInterval,
    importLinkError,
    setImportLinkError,
    search,
    setSearch,
    print,
    setSelectAllPaginatedBadges,
    selectAllPaginatedBadges,
    getSiteData
  } = useSiteBadgesListViewModel(siteId);

  const [linkBadgesModal, setLinkBadgesModal] = useState(false);
  const [addBadgesModal, setAddBadgesModal] = useState(false);
  const [deleteCandidateBadge, setDeleteCandidateBadge] = useState<string[]>(
    []
  );
  const [importLinkModal, setImportLinkModal] = useState(false);
  const [selectedBadges, setSelectedBadges] = useState<string[]>([]);
  const [selectAllVisibleBadges, setSelectAllVisibleBadges] =
    useState<boolean>(false);
  const namespace =
    importLinkError !== "cannotLinkResourceToBadge" ? "common" : "errors";

  const templatePath = () => {
    switch (resourceType) {
      case BadgeResourceType.WORKER:
        return "/documents/TemplateImportLavoratori-Badge.xlsx";
      case BadgeResourceType.MACHINE:
        return "/documents/TemplateImportMacchinari-Badge.xlsx";
      case BadgeResourceType.VEHICLE:
        return "/documents/TemplateImportVeicoli-Badge.xlsx";
      default:
        return null;
    }
  };

  const toggleItem = (id: string) => {
    if (!selectedBadges.includes(id)) {
      setSelectedBadges([...selectedBadges, id]);
    } else {
      setSelectedBadges(selectedBadges.filter((i) => i !== id));
    }
  };

  const toggleSelectAll = (value: boolean) => {
    setSelectAllVisibleBadges(value);
    if (!value) {
      setSelectedBadges([]);
      setSelectAllPaginatedBadges(false);
    } else {
      setSelectedBadges(siteBadges.map((badge) => badge.id));
    }
  };
  const tableColumns: {
    field: keyof GetSiteBadgesFilters;
    type: "text" | "select" | "tags";
    options?: Record<string, string>;
  }[] = [
    { field: "code", type: "text" },
    { field: "type", type: "select", options: BadgeType },
    { field: "serial", type: "text" },
    { field: "tagIds", type: "tags" },
    { field: "resourceType", type: "select", options: BadgeResourceType },
    { field: "resourceName", type: "text" },
    { field: "company", type: "text" },
    { field: "status", type: "select", options: BadgeStatus },
  ];

  const openModalUploadLink = () => {
    setLinkBadgesModal(false);
    setImportLinkModal(true);
  };

  const [isTablet] = useMediaQuery("(max-width: 1300px)");

  const handleSelectionButton = () => {
    if (selectAllPaginatedBadges) {
      setSelectedBadges([]);
      setSelectAllVisibleBadges(false);
      setSelectAllPaginatedBadges(false);
    } else {
      setSelectAllPaginatedBadges(true);
    }
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={managePermissions}>
            <ActionBarItem
              onClick={() => {
                setAddBadgesModal(true);
                getAvailableBadges();
              }}
              icon={GrChapterAdd}
              description={t("addToSite")}
            />
            <ActionBarItem
              onClick={() => {
                setLinkBadgesModal(true);
                setResourceType(BadgeResourceType.WORKER);
              }}
              icon={AiOutlineUsergroupAdd}
              description={t("linkToSiteResources")}
              bgColor={COLORS.sikuroBlue}
              color="white"
            />
            <SiteBadgesPrint
              siteBadges={siteBadges}
              selectedBadges={selectedBadges}
              printAllBadges={selectAllPaginatedBadges}
              print={print}
              resetSelectedBadges={() => {
                setSelectedBadges([]);
                setSelectAllVisibleBadges(false);
                setSelectAllPaginatedBadges(false);
              }}
              getSiteData={getSiteData}
            />
            <ActionBarItem
              onClick={() => {
                setDeleteCandidateBadge(selectedBadges);
              }}
              icon={MdDelete}
              description={t("deleteBadges")}
              disabledDescription={t("noBadgesSelected")}
              isDisabled={selectedBadges.length === 0}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        w="100%"
        padding={10}
        textAlign="center"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
      >
        <Flex alignItems="center" width="100%" justifyContent={"space-between"}>
          <div style={{ textAlign: "left" }}>
            <Text
              textColor={COLORS.sikuroBlue}
              fontSize={20}
              fontWeight={"bold"}
            >
              {t("title")}
            </Text>
            <Text>{t("siteSubtitle")}</Text>
          </div>
        </Flex>

        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width={"calc(100vw - 264px)"}
          marginTop={3}
          overflow={"auto"}
        >
          <InfiniteTable
            tableId="site-badges-table"
            autosize={true}
            infiniteScroll={{
              dataLength: siteBadges.length,
              hasNextPage: siteBadgesHasNextPage,
              fetchNextPage: siteBadgesFetchNextPage,
            }}
            isLoading={siteBadgesIsLoading}
            emptyText={
              Object.keys(siteBadgesFilters).length > 0
                ? t("noBadgesFound", { ns: "badges" })
                : t("noSiteBadges", { ns: "badges" })
            }
          >
            <Thead>
              <Tr>
                <Th key={"selectAllCheckbox"} width={10}>
                  <Checkbox
                    borderColor={"gray.500"}
                    isChecked={selectAllVisibleBadges}
                    onChange={() => toggleSelectAll(!selectAllVisibleBadges)}
                  ></Checkbox>
                </Th>
                {tableColumns.map((column) => (
                  <Th
                    key={column.field}
                    width={
                      isTablet
                        ? "200px"
                        : column.field === "serial"
                        ? "230px"
                        : undefined
                    }
                  >
                    <TableColumnHeader
                      text={t(`columns.${column.field}`)}
                      filter={{
                        component: (
                          <ColumnFilterComponent
                            type={column.type}
                            value={siteBadgesFilters[column.field]}
                            updateFilter={(value) =>
                              updateSiteBadgesFilter(
                                column.field,
                                value as string | string[]
                              )
                            }
                            tags={siteBadgesTags}
                            selectOptions={column.options}
                            namespace="badges"
                          />
                        ),
                        isActive: !!(Array.isArray(
                          siteBadgesFilters[column.field]
                        )
                          ? siteBadgesFilters[column.field][0]
                          : siteBadgesFilters[column.field]),
                      }}
                      sort={{
                        handler: (direction) =>
                          setSiteBadgesSort({ field: column.field, direction }),
                        direction:
                          siteBadgesSort &&
                          siteBadgesSort.field === column.field
                            ? siteBadgesSort.direction
                            : null,
                      }}
                    />
                  </Th>
                ))}
                <Th key={"actionColumn"} width={10}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {selectAllVisibleBadges && (
                <Tr>
                  <Th
                    colSpan={tableColumns.length + 1}
                    backgroundColor={"gray.100"}
                    width={"100%"}
                  >
                    <Text textAlign="center" mx="auto">
                      {!selectAllPaginatedBadges && t("badgesSelectedVisible")}
                      {selectAllPaginatedBadges &&
                        t("badgesSelectedNotVisible", {
                          count: totalBadgeRegisteredCount,
                        })}

                      {siteBadgesHasNextPage && (
                        <Button
                          mt="10px"
                          ml="4px"
                          colorScheme="blue"
                          variant="link"
                          onClick={() => handleSelectionButton()}
                        >
                          {t(
                            selectAllPaginatedBadges
                              ? "clearSelection"
                              : "badgesSelectAll",
                            { ns: "badges" }
                          )}
                        </Button>
                      )}
                    </Text>
                  </Th>
                </Tr>
              )}
              {siteBadges.map((badge) => (
                <Tr
                  key={badge.id}
                  onClick={() => navigate(`${badge.id}`)}
                  sx={{ cursor: "pointer" }}
                >
                  <Td width={10} onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      borderColor={"gray.500"}
                      isChecked={
                        selectedBadges
                          ? selectedBadges?.includes(badge?.id)
                          : false
                      }
                      onChange={(e) => {
                        toggleItem(badge?.id);
                        e.stopPropagation();
                      }}
                    ></Checkbox>
                  </Td>
                  <Td width={isTablet ? "200px" : undefined}>{badge.code}</Td>
                  <Td width={isTablet ? "200px" : undefined}>
                    {t(badge.type)}
                  </Td>
                  <Td width={isTablet ? "200px" : "230px"}>
                    {badge.serial ? badge.serial : "-"}
                  </Td>
                  <Td width={isTablet ? "200px" : undefined}>
                    <TagList tags={badge.tags} />
                  </Td>
                  <Td width={isTablet ? "200px" : undefined}>
                    {badge.resource?.type ? t(badge.resource?.type) : "-"}
                  </Td>
                  <Td width={isTablet ? "200px" : undefined}>
                    {badge.resource?.name ? t(badge.resource?.name) : "-"}
                  </Td>
                  <Td width={isTablet ? "200px" : undefined}>
                    {badge.resource?.companyName
                      ? badge.resource?.companyName
                      : "-"}
                  </Td>
                  <Td width={isTablet ? "200px" : undefined}>
                    <StatusBadge value={badge.status} />
                  </Td>
                  <Td p={0} w={10}>
                    <RenderIf permissions={managePermissions}>
                      <DeleteButton
                        onClick={(e) => {
                          setDeleteCandidateBadge([badge?.id]);
                          e.stopPropagation();
                        }}
                      />
                    </RenderIf>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </InfiniteTable>
        </Flex>
      </Flex>

      {deleteCandidateBadge && deleteCandidateBadge.length > 0 && (
        <ConfirmAlert
          variant="warning"
          title={t("warning", { ns: "common" })}
          message={
            deleteCandidateBadge.length > 1
              ? t("askUnlinkBadges")
              : t("askUnlinkBadge")
          }
          onCancel={() => setDeleteCandidateBadge(undefined)}
          onConfirm={() => {
            unlinkBadgeFromSite({
              badgeIds: deleteCandidateBadge,
              selectAll: selectAllPaginatedBadges,
            });
            setDeleteCandidateBadge(undefined);
            setSelectedBadges([]);
            setSelectAllVisibleBadges(false);
            setSelectAllPaginatedBadges(false);
          }}
        />
      )}

      {addBadgesModal && (
        <AddBadgesToSiteModal
          onClose={() => setAddBadgesModal(false)}
          onConfirm={(badgeIds, selectAll, selectedBadgesNumber) =>
            linkBadgesToSite({
              badgeIds,
              selectAll,
              selectedBadgesNumber,
              availableBadgesFilters,
            })
          }
          availableBadges={availableBadges}
          availableBadgesTags={availableBadgesTags}
          availableBadgesHasNextPage={availableBadgesHasNextPage}
          availableBadgesFetchNextPage={availableBadgesFetchNextPage}
          availableBadgesFilters={availableBadgesFilters}
          updateAvailableBadgesFilter={updateAvailableBadgesFilter}
          availableBadgesSort={availableBadgesSort}
          setAvailableBadgesSort={setAvailableBadgesSort}
          refetchBadgeSiteFromInterval={refetchBadgeSiteFromInterval}
          setBadgesInterval={setBadgesInterval}
          badgesInterval={badgesInterval}
          badgeSiteInterval={badgeSiteInterval}
          associatedSites={badgeSites}
          badgeSitesIsLoading={availableBadgeSitesIsLoading}
          setBadgeId={setBadgeId}
          selectableBadgesCount={siteAvailableBadgesTotalCount}
          linkBadgesToSiteIsLoading={linkBadgesToSiteIsLoading}
        />
      )}

      {linkBadgesModal && (
        <LinkBadgesToSiteResourcesModal
          onClose={() => setLinkBadgesModal(false)}
          onConfirm={async (
            badgeIds,
            resourceIds,
            selectAllResources,
            selectedResourcesNumber,
            siteResourcesFilters,
            selectAllBadges,
            selectedBadgesNumber,
            availableBadgesFilters,
            automaticLinking
          ) => {
            await linkBadgesToSiteResources({
              badgeIds,
              resourceIds,
              selectAllResources,
              selectedResourcesNumber,
              siteResourcesFilters,
              selectAllBadges,
              selectedBadgesNumber,
              availableBadgesFilters,
              automaticLinking,
            });
            setResourceType(undefined);
          }}
          availableBadges={availableSiteBadges}
          availableBadgesCount={availableSiteBadgesCount}
          availableBadgesTags={availableSiteBadgesTags}
          availableBadgesHasNextPage={availableSiteBadgesHasNextPage}
          availableBadgesFetchNextPage={availableSiteBadgesFetchNextPage}
          availableBadgesFilters={availableSiteBadgesFilters}
          updateAvailableBadgesFilter={updateAvailableSiteBadgesFilter}
          availableBadgesSort={availableSiteBadgesSort}
          setAvailableBadgesSort={setAvailableSiteBadgesSort}
          siteResources={siteResources}
          siteResourcesIsLoading={siteResourcesIsLoading}
          registeredSiteResourceCount={siteResourcesCount}
          resourceType={resourceType}
          setResourceType={setResourceType}
          siteResourcesFilters={siteResourcesFilters}
          updateSiteResourcesFilter={updateSiteResourcesFilter}
          siteResourcesSort={siteResourcesSort}
          setSiteResourcesSort={setSiteResourcesSort}
          siteResourcesHasNextPage={siteResourcesHasNextPage}
          siteResourcesFetchNextPage={siteResourcesFetchNextPage}
          openFileImport={openModalUploadLink}
          linkingIsLoading={linkingIsLoading}
          search={search}
          setSearch={setSearch}
        />
      )}

      {!importLinkError && importLinkModal && (
        <ImportLinkResourceBadgeModal
          onClose={() => setImportLinkModal(false)}
          onConfirm={importLinkResourcesBadges}
          resourceType={resourceType}
        />
      )}

      {importLinkError && (
        <BaseModal
          type="warning"
          title={t("warning", { ns: "common" })}
          onClose={() => {
            setResourceType(null);
            setImportLinkError(null);
            setImportLinkModal(false);
          }}
          onConfirm={() => {
            setResourceType(null);
            setImportLinkError(null);
            setImportLinkModal(false);
          }}
          onConfirmLabel={t("close", { ns: "common" })}
          onCancel={false}
        >
          <Text>
            <Trans
              i18nKey={importLinkError}
              ns={namespace}
              components={{
                a: (
                  <Link
                    sx={{ color: COLORS.sikuroBlue }}
                    href={templatePath()}
                  />
                ),
              }}
            />
          </Text>
        </BaseModal>
      )}
    </ContentLayout>
  );
};

export default SiteBadgesView;
