import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';
import Requirement from '../../../domain/entities/requirement';
import { RequirementSubject } from '../../../domain/entities/requirementSubject.enum';
import Tag from '../../../domain/entities/tag';
import { DocumentTypeAdd, GetSitesFilter } from '../../../domain/repositories/siteRepository';
import { DocumentTypeResourceType } from '../../../mock/models/document-type';
import { useAuth } from '../../providers/Auth0JWTProvider';
import { RequirementsViewModel } from '../../viewmodels/requirements/RequirementsViewModel';
import useAddDocumentType from '../Document/useAddDocumentType';
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import Variant from '../../../domain/entities/variant';
import Specialization from '../../../domain/entities/specialization';
import FileEntity from '../../../domain/entities/file';
import { useParams } from 'react-router-dom';
import { updateFilterWithDelete } from "../../../utils";

export type  UpdateFilter = (column: string, value: string | string[] | [Date, Date]) => void;
function createUseRequirementsViewModel(viewModelFactory: () => RequirementsViewModel) {
	return function useRequirementsViewModel(requirementGroupId: string, requirementSubject: RequirementSubject) {
		const { companyId } = useAuth();
		const { siteId } = useParams();
		const viewModel = viewModelFactory();
		const queryClient = useQueryClient();
		const [variantRenameError, setVariantRenameError] = useState<string>();
		const [requirementWithVariantAlreadyExistsError, setRequirementWithVariantAlreadyExistsError] = useState<string>();
		const [specializationRenameError, setSpecializationRenameError] = useState<string>();
		const [sortSites, setSortSites] = useState<SortMeta>();
		const [filterSites, setFilterSites] = useState<GetSitesFilter>();
		const [variantId, setVariantId] = useState<string>();
		const [reqByVariant, setReqByVariant] = useState(false);
		const [reqGroupByVariant, setReqGroupByVariant] = useState(false);
		const [supplierRequirementSource, setSupplierRequirementSource] = useState<RequirementSubject>(undefined);
		const [enableGetPropagableSites, setEnablableGetPropagableSites] = useState<boolean>(false);
		const [availableSitesCount, setAvailableSitesCount] = useState<number>();

		useEffect(() => {
			if (variantId) {
				if (siteId) {
					setReqGroupByVariant(false);
					setReqByVariant(true);
				} else {
					setReqByVariant(false);
					setReqGroupByVariant(true);
				}
			}
		}, [variantId]);

		const {
			data: requirements,
			error,
			isLoading: requirementsLoading,
			refetch: refetchRequirements,
		} = useQuery<Requirement[], Error>(
			[`group-requirements-${requirementSubject}`, companyId, requirementSubject],
			() => {
				if (requirementSubject){
					return viewModel.getGroupRequirementsByResource(companyId, requirementGroupId, requirementSubject);
				}else{
					return [];
				}
			},
			{ retry: false }
		);

		const {
			data: requirementsByVariant,
			isLoading: requirementsByVariantLoading,
			isFetching: requirementsByVariantFetching,
			refetch: refetchRequirementsByVariant,
		} = useQuery<Requirement[], Error>(
			['requirementsByVariant', companyId, siteId, variantId, requirementSubject],
			() => viewModel.getRequirementsByVariant(companyId, siteId, variantId, requirementSubject),
			{ retry: false, enabled: reqByVariant }
		);

		const {
			data: requirementsGroupByVariant,
			isLoading: requirementsGroupByVariantLoading,
			isFetching: requirementsGroupByVariantFetching,
			refetch: refetchRequirementsGroupByVariant,
		} = useQuery<Requirement[], Error>(
			['requirementsGroupByVariant', companyId, siteId, variantId, requirementSubject],
			() => viewModel.getRequirementsGroupByVariant(companyId, variantId, requirementGroupId, requirementSubject),
			{ retry: false, enabled: reqGroupByVariant }
		);

		const {
			data: variants,
			refetch: refetchVariants,
			isLoading: isVariantsLoading,
		} = useQuery(
			['variants', companyId, requirementSubject],
			async () => {
				if (requirementSubject){
					return requirementSubject && (await viewModel.listVariants(companyId, requirementSubject as unknown as DocumentTypeResourceType));
				}else{
					return [];
				}
			},
			{
				enabled: !(location.pathname.includes('sites') && location.pathname.includes('resources')),
			}
		);

		const {
			data: siteVariants,
			refetch: refetchSiteVariants,
			isLoading: isSiteVariantsLoading,
		} = useQuery(['site-variants', companyId, requirementSubject], async () => {
			if (requirementSubject && siteId){
				return requirementSubject && (await viewModel.listSiteVariants(companyId, siteId, requirementSubject as unknown as DocumentTypeResourceType));
			}else{
				return [];
			}
		});

		const createRequirementVariantMutation = useMutation(
			async (variant: Variant) => {
				return viewModel.createRequirementVariant(companyId, variant, requirementSubject);
			},
			{
				onSuccess: () => {
					refetchRequirements();
					refetchVariants();
				},
			}
		);

		const createRequirementSpecializationMutation = useMutation(
			async (specialization: Specialization) => {
				return viewModel.createRequirementSpecialization(companyId, specialization, requirementSubject);
			},
			{
				onSuccess: () => {
					refetchRequirements();
					refetchSpecializations();
				},
			}
		);

		const linkVariantToRequirementMutation = useMutation(
			async ({ variant, requirementId }: { variant: Tag; requirementId: string }) =>
				await viewModel.addVariantToRequirement(
					companyId,
					requirementId,
					variant,
					requirementGroupId,
					requirementSubject as unknown as DocumentTypeResourceType
				),
			{
				onSuccess: () => {
					refetchRequirements();
					refetchVariants();
				},
			}
		);

		const unlinkVariantFromRequirementMutation = useMutation(
			async ({ variantId, requirementId }: { variantId: string; requirementId: string }) =>
				await viewModel.removeVariantFromRequirement(companyId, requirementId, variantId, requirementGroupId),
			{
				onSuccess: () => {
					refetchRequirements();
					refetchVariants();
				},
			}
		);
		// manage update variant
		const updateVariantMutation = useMutation(
			async (tag: Tag) => {
				return await viewModel.updateVariantFromRequirement(companyId, tag);
			},
			{
				onSuccess: () => {
					refetchRequirements();
					refetchVariants();
				},
				onError: (e) => {
					refetchRequirements();
					refetchVariants();
					console.error(e);
				},
			}
		);

		const updateVariant = async (tag: Tag) => {
			try {
				await updateVariantMutation.mutateAsync(tag);
			} catch (err) {
				setVariantRenameError('existingVariant');
			}
		};

		// manage update specialization
		const updateSpecializationMutation = useMutation(
			async (tag: Tag) => {
				return await viewModel.updateSpecializationFromRequirement(companyId, tag);
			},
			{
				onSuccess: () => {
					refetchRequirements();
					refetchSpecializations();
				},
				onError: async (e) => {
					console.error(e);
					await refetchRequirements();
					await refetchSpecializations();
				},
			}
		);

		const updateSpecialization = async (tag: Tag) => {
			try {
				await updateSpecializationMutation.mutateAsync(tag);
			} catch (err) {
				setSpecializationRenameError('existingSpecialization');
			}
		};

		const {
			data: specializations,
			refetch: refetchSpecializations,
			isLoading: isSpecializationsLoading,
		} = useQuery(
			['specializations-requirements', companyId, requirementSubject],
			async () => {
				if (requirementSubject === RequirementSubject.SUPPLIER || requirementSubject === undefined) {
					return [];
				}
				return await viewModel.listSpecializations(companyId, requirementSubject as unknown as DocumentTypeResourceType)
			},
		);

		const linkSpecializationToRequirementMutation = useMutation(
			async ({ specialization, requirementId }: { specialization: Tag; requirementId: string }) =>
				await viewModel.addSpecializationToRequirement(
					companyId,
					requirementId,
					specialization,
					requirementGroupId,
					requirementSubject as unknown as DocumentTypeResourceType
				),
			{
				onSuccess: () => {
					refetchRequirements();
					refetchSpecializations();
				},
			}
		);

		const unlinkSpecializationFromRequirementMutation = useMutation(
			async ({ specializationId, requirementId }: { specializationId: string; requirementId: string }) =>
				await viewModel.removeSpecializationFromRequirement(companyId, requirementId, specializationId, requirementGroupId),
			{
				onSuccess: () => {
					refetchRequirements();
					refetchSpecializations();
				},
			}
		);

		const addRequirementToGroupMutation = useMutation(
			async (params: { documents: DocumentTypeAdd[]; siteIds?: string[], selectAllSites?: boolean, filters?: GetSitesFilter }) => {
				await viewModel.addRequirementToGroup(companyId, requirementGroupId, params.documents, requirementSubject, params.siteIds, params.selectAllSites, params.filters);
			},
			{
				onError: (err: Error) => {
					console.error(err);
					setRequirementWithVariantAlreadyExistsError(err.message)
				},
				onSuccess: () => {
					refetchRequirements();
				},
			}
		);

		const addRequirementToSiteMutation = useMutation(
			async (documents: DocumentTypeAdd[]) => await viewModel.addRequirementToSite(companyId, requirementGroupId, documents, requirementSubject),
			{
				onError: (err) => console.error(err),
				onSuccess: () => refetchRequirements(),
			}
		);

		const removeRequirementFromGroupMutation = useMutation(
			async (params: { requirementId: string; siteIds?: string[], selectAllSites?: boolean, filters?: GetSitesFilter }) =>
				await viewModel.removeRequirementFromGroup(companyId, requirementGroupId, params.requirementId, params.siteIds, params.selectAllSites, params.filters),
			{
				onError: (err) => console.error(err),
				onSuccess: () => refetchRequirements(),
			}
		);

		const removeRequirementFromSiteMutation = useMutation(
			async (requirementId: string) => await viewModel.removeRequirementFromSite(companyId, requirementGroupId, requirementId),
			{
				onError: (err) => console.error(err),
				onSuccess: () => refetchRequirements(),
			}
		);

		const updateRequirementMutation = useMutation(
			async (params: { requirement: Requirement; siteIds?: string[],selectAllSites?: boolean, filters?: GetSitesFilter }) =>
				await viewModel.updateRequirement(companyId, params.requirement, requirementGroupId, params.siteIds, params.selectAllSites, params.filters),
			{
				onError: (err: Error) => {
					console.error(err);
					setRequirementWithVariantAlreadyExistsError(err.message)
				},
				onSuccess: () => refetchRequirements(),
			}
		);

		const moveRequirementsByVariantMutation = useMutation(
			async (requirements: Requirement[]) => {
				return viewModel.updateRequirementsByVariantOrder(companyId, siteId, variantId, requirements);
			},
			{
				onMutate: async (newRequirements: Requirement[]) => {
					await queryClient.cancelQueries(['requirementsByVariant', companyId, siteId, variantId, requirementSubject]);
					return;
				},
				onError: (err, newRequirement, context) => {
					queryClient.setQueryData(
						['requirementsByVariant', companyId, siteId, variantId, requirementSubject],
						queryClient.getQueryData(['requirementsByVariant', companyId, siteId, variantId, requirementSubject]) as Requirement[]
					);
				},
				onSuccess: () => refetchRequirementsByVariant(),
			}
		);

		const moveRequirementsGroupByVariantMutation = useMutation(
			async (requirements: Requirement[]) => {
				return viewModel.updateRequirementsGroupByVariantOrder(companyId, variantId, requirementGroupId, requirements);
			},
			{
				onMutate: async (newRequirements: Requirement[]) => {
					await queryClient.cancelQueries(['requirementsGroupByVariant', companyId, variantId, requirementGroupId, requirementSubject]);
					return;
				},
				onError: (err, newRequirement, context) => {
					queryClient.setQueryData(
						['requirementsGroupByVariant', companyId, variantId, requirementGroupId, requirementSubject],
						queryClient.getQueryData([
							'requirementsGroupByVariant',
							companyId,
							variantId,
							requirementGroupId,
							requirementSubject,
						]) as Requirement[]
					);
				},
				onSuccess: () => refetchRequirementsGroupByVariant(),
			}
		);

		const moveRequirementMutation = useMutation(
			async (requirements: Requirement[]) => {
				return await viewModel.updateRequirementsOrder(companyId, requirements, requirementGroupId)
			},
			{
				onMutate: async (newRequirements: Requirement[]) => {
					await queryClient.cancelQueries(['group-requirements', companyId, requirementSubject]);
					return;
				},
				onError: (err, newRequirement, context) => {
					queryClient.setQueryData(
						['group-requirements', companyId, requirementSubject],
						queryClient.getQueryData(['group-requirements', companyId, requirementSubject]) as Requirement[],
					);
				},
				onSettled: () => {
					queryClient.invalidateQueries(['group-requirements', companyId, requirementSubject]);
				},
				onSuccess: () => {
					refetchRequirements();
				}
			}

		);

		const addRequirementToGroup = async (documents: DocumentTypeAdd[], siteIds?: string[], selectAllSites?: boolean) => {
			try {
				await addRequirementToGroupMutation.mutateAsync({ documents, siteIds, selectAllSites, filters: filterSites });
			} catch (err) {
				console.error('cannot add requirement to group', err);
			}
		};

		const addRequirementToSite = async (documents: DocumentTypeAdd[]) => {
			try {
				await addRequirementToSiteMutation.mutateAsync(documents);
			} catch (err) {
				console.error('cannot add requirement to site', err);
			}
		};

		const removeRequirementFromGroup = async (requirement: Requirement, siteIds?: string[],selectAllSites?: boolean) => {
			try {
				await removeRequirementFromGroupMutation.mutateAsync({ requirementId: requirement.id, siteIds, selectAllSites, filters: filterSites });
			} catch (err) {
				console.error('cannot remove requirement from group', err);
			}
		};

		const removeRequirementFromSite = async (requirement: Requirement) => {
			try {
				await removeRequirementFromSiteMutation.mutateAsync(requirement.id);
			} catch (err) {
				console.error('cannot remove requirement from site', err);
			}
		};

		const updateRequirement = async (requirement: Requirement, siteIds: string[], selectAllSites?: boolean) => {
			try {
				await updateRequirementMutation.mutateAsync({ requirement, siteIds, selectAllSites , filters: filterSites });
			} catch (err) {
				console.error('cannot update requirement', err);
			}
		};

		const isTagLoading =
			isVariantsLoading ||
			isSpecializationsLoading ||
			linkVariantToRequirementMutation.isLoading ||
			unlinkVariantFromRequirementMutation.isLoading ||
			linkSpecializationToRequirementMutation.isLoading ||
			unlinkSpecializationFromRequirementMutation.isLoading ||
			updateVariantMutation.isLoading ||
			updateSpecializationMutation.isLoading ||
			requirementsLoading;

		const getPropagableSites = useInfiniteQuery(
			['get-propagable-sites', filterSites, sortSites, enableGetPropagableSites],
			async ({ pageParam = 1 }) => {
				const { results, count } = await viewModel.getPropagableSites(companyId, requirementGroupId, filterSites, sortSites, pageParam);
				setAvailableSitesCount(count);
				return results;
				},
			{
				getNextPageParam: (lastPage, pages) => {
					if (lastPage?.length === 25) {
						return pages.length + 1;
					}
				},
				enabled: enableGetPropagableSites
			}
		);

		const addTemplateToSiteRequirement = async (requirementId: string, file: FileEntity) => {
			try {
				await viewModel.addTemplateToSiteRequirement(companyId, siteId, requirementId, file);
				refetchRequirements();
			} catch (err) {
				console.error('cannot add template to site', err);
			}
		};

		const deleteTemplateFromSiteRequirement = async (requirementId: string, templateId: string) => {
			try {
				await viewModel.deleteTemplateFromSiteRequirement(companyId, siteId, requirementId, templateId);
				refetchRequirements();
			} catch (err) {
				console.error('cannot remove template from requirement site', err);
			}
		};

		const availableSites = getPropagableSites.data?.pages.flat() ?? [];

		const getRequirementTemplateUrl = async (requirementId: string, templateId: string, onComplete: () => void) => {
			return await viewModel.getRequirementsTemplateUrl(companyId, siteId,requirementId, templateId).then(onComplete)
		}

		return {
			error,
			requirements,
			supplierRequirementSource,
			setSupplierRequirementSource,
			requirementsLoading,
			addRequirementToGroup,
			addRequirementToSite,
			removeRequirementFromGroup,
			removeRequirementFromGroupIsBusy: removeRequirementFromGroupMutation.isLoading,
			removeRequirementFromSite,
			updateRequirement,
			requirementsGroupByVariant,
			refetchRequirementsGroupByVariant,
			requirementsGroupByVariantLoading,
			requirementsGroupByVariantFetching,
			variants,
			siteVariants,
			isTagLoading,
			requirementsByVariant,
			refetchRequirementsByVariant,
			requirementsByVariantLoading,
			setVariantId,
			setReqGroupByVariant,
			setReqByVariant,
			linkVariantToRequirement: linkVariantToRequirementMutation.mutate,
			unlinkVariantFromRequirement: unlinkVariantFromRequirementMutation.mutate,
			specializations,
			requirementsByVariantFetching,
			requirementsByVariantIsBusy: moveRequirementsByVariantMutation.isLoading,
			requirementsByVariantGroupIsBusy: moveRequirementsGroupByVariantMutation.isLoading,
			linkSpecializationToRequirement: linkSpecializationToRequirementMutation.mutate,
			unlinkSpecializationFromRequirement: unlinkSpecializationFromRequirementMutation.mutate,
			moveRequirement: moveRequirementMutation.mutate,
			updateVariant,
			variantRenameError,
			moveRequirementsByVariant: moveRequirementsByVariantMutation.mutate,
			moveRequirementsGroupByVariant: moveRequirementsGroupByVariantMutation.mutate,
			updateSpecialization,
			specializationRenameError,
			documentTypesProps: useAddDocumentType(viewModel, requirementSubject as unknown as DocumentTypeCategory),
			availableSites,
			availableSitesHasNextPage: getPropagableSites.hasNextPage,
			availableSitesFetchNextPage: getPropagableSites.fetchNextPage,
			availableSitesCount,
			availableSitesIsFetching: getPropagableSites.isFetching,
			setEnablableGetPropagableSites,
			setSortSites,
			sortSites,
			filterSites,
			setFilterSites,
			updateAvailableSites: (field: string, value: string | string[] | [Date, Date]) => {
				updateFilterWithDelete(setFilterSites, field, value);
			},
			createVariant: createRequirementVariantMutation.mutateAsync,
			createVariantIsBusy: createRequirementVariantMutation.isLoading,
			createSpecialization: createRequirementSpecializationMutation.mutateAsync,
			createSpecializationIsBusy: createRequirementSpecializationMutation.isLoading,
			addTemplateToSiteRequirement,
			deleteTemplateFromSiteRequirement,
			requirementWithVariantAlreadyExistsError,
			setRequirementWithVariantAlreadyExistsError,
			getRequirementTemplateUrl
		};
	};
}

export { createUseRequirementsViewModel };