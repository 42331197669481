import { Box, Flex, Icon, Link, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle, AiFillWarning } from "react-icons/ai";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { useApp } from "../../../../app";
import { SiteResourceDocument } from "../../../../domain/entities/document";
import Evaluation from "../../../../domain/entities/evaluation";
import FileEntity from "../../../../domain/entities/file";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import { formatDateWithGraceDays, processUploadedFiles } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import { DocumentCommunicationHook } from "../../../hooks/Document/useDocumentCommunicationVIewModel";
import { UseDocumentInfo } from "../../../hooks/Document/useDocumentInfo";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import {
  EvaluateDocumentParams,
  Filter,
  Sort,
  UpdateFilter,
  UpdateSort
} from "../../../hooks/Site/useSiteResourceDetailViewModel";
import { Alert } from "../../../screens/Common/Alert";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import CommentButton from "../../Common/CommentButton";
import { EvaluationButton } from "../../Common/ResourceEvaluationToggle";
import TextInput from "../../Common/TextInput";
import AddFileButton from "../../Common/table/AddFileButton";
import DeleteButton from "../../Common/table/DeleteButton";
import HistoryButton from "../../Common/table/HistoryButton";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import { Permission } from "../../Permissions/Permissions";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import { DocumentDetailModal } from "./DocumentDetailModal";
import DocumentInfoModal from "./DocumentInfoModal";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { LuFileSearch2 } from "react-icons/lu";
import { UseDocumentAI } from "../../../hooks/Document/useDocumentAI";
import { AiTaskSectionCode } from "../../../../domain/entities/aiTaskSectionCode";
import ManageTaskWithAiModal from "../Documents/ManageTaskWithAiModal";
import { PermissionCheck, useAuth } from "../../../providers/Auth0JWTProvider";
import DocumentEvaluationToggle from "./DocumentEvaluationToggle";
import { downloadFileFromURL } from "../../../../infrastructure/utilities/fileDownloader";

type SiteResourceDocumentsProps = UseDocumentInfo & {
  documents: SiteResourceDocument[];
  documentsRefetch: () => void;
  documentsHasNextPage;
  documentsFetchNextPage;
  evaluateDocument?: (params: EvaluateDocumentParams) => void;
  siteResourceRefetch?: () => void;
  evaluateDocumentIsLoading?: boolean;
  sortDocuments: SortMeta;
  updateSortDocuments: (sort: SortMeta) => void;
  filterDocuments: Filter;
  updateFilterDocuments: UpdateFilter;
  setSiteResourceHookDocumentId: (documentId: string) => void;
  documentEvaluations: Evaluation<ResourceDocumentEvaluationState>[];
  documentEvaluationsFetching: boolean;
  updateFilterDocumentEvaluations: UpdateFilter;
  filterDocumentEvaluations: Filter;
  sortDocumentEvaluations: Sort;
  updateSortDocumentEvaluations: UpdateSort;
  isWorkingSite: boolean;
  onDelete?: (requirementId: string, subject: RequirementSubject) => void;
  requirementSubject?: RequirementSubject;
  downloader: UseDownloader;
  isFetchingDocuments?: boolean;
  isLoadingDocuments?: boolean;
  isCompanySupplier?: boolean;
  documentLogsFetching: boolean;
  documentFilesFetching: boolean;
  isReadOnly?: boolean;
  setDocumentTypeId?: (documentId: string) => void;
  communicationProps: DocumentCommunicationHook;
  showCommunications?: boolean;
  autosize?: boolean;
  permissions: ResourceDocumentsPermissions;
  resourceId?: string;
  resourceCategory?: DocumentTypeCategory;
  deltaWidth?: string;
  isTablet: boolean;
  isSubcontract?: boolean;
  showManageTaskWithAi?: boolean;
  setShowManageTaskWIthAi?: (param: boolean) => void;
  documentsAiHook?: UseDocumentAI;
  aiTaskSectionCode?: AiTaskSectionCode;
  canAddFile?: boolean;
  getRequirementTemplateUrl?: (requirementId: string, templateId: string, onComplete: (url: string) => Promise<void>) => Promise<void>
};

export type ResourceDocumentsPermissions = {
  delete: Permission;
  evaluate: Permission;
  viewEvaluations: Permission;
  edit: Permission;
  downloadAll: Permission;
  addFile: Permission;
  setExpiration: Permission;
  chat: Permission;
  report?: Permission;
  addNewFile?: Permission;
};

const SiteResourceDocuments = ({
                                 documents,
                                 documentsRefetch,
                                 documentsHasNextPage,
                                 documentsFetchNextPage,
                                 evaluateDocument,
                                 evaluateDocumentIsLoading,
                                 sortDocuments,
                                 updateSortDocuments,
                                 updateFilterDocuments,
                                 filterDocuments,
                                 downloader,
                                 updateFile,
                                 documentEvaluations,
                                 documentEvaluationsFetching,
                                 filterDocumentEvaluations,
                                 updateFilterDocumentEvaluations,
                                 sortDocumentEvaluations,
                                 updateSortDocumentEvaluations,
                                 documentFileLogs,
                                 addFileToDocument,
                                 isAddingFile,
                                 successMsg,
                                 setSuccessMsg,
                                 deleteDocumentFile,
                                 documentFiles,
                                 updateFilterDocumentLog,
                                 filterDocumentLog,
                                 sortDocumentLog,
                                 setSortDocumentLog,
                                 isWorkingSite,
                                 onDelete,
                                 requirementSubject,
                                 isFetchingDocuments,
                                 isLoadingDocuments,
                                 documentLogsFetching,
                                 documentFilesFetching,
                                 isReadOnly = false,
                                 setEnableGetDocLogs,
                                 setEnableGetFileLogs,
                                 communicationProps,
                                 renameModel,
                                 refetchRenameModel,
                                 handleDocumentTypeId,
                                 deleteIsLoading,
                                 errorMsg,
                                 setErrorMsg,
                                 permissions,
                                 resourceId,
                                 resourceCategory,
                                 setHookDocumentId,
                                 setSiteResourceHookDocumentId,
                                 siteResourceRefetch,
                                 deltaWidth,
                                 isTablet,
                                 isSubcontract,
                                 showCommunications,
                                 showManageTaskWithAi,
                                 setShowManageTaskWIthAi,
                                 documentsAiHook,
                                 aiTaskSectionCode,
                                 canAddFile,
                                 getRequirementTemplateUrl
                               }: SiteResourceDocumentsProps) => {
  const { context } = useApp();
  const { t } = useTranslation();
  const [showDocumentInfoModal, setShowDocumentInfoModal] = useState<
    false | { documentId: string; requirementId: string; tab: 0 | 1 }
  >(false);
  const [showConfirmUploadeModal, setShowConfirmUploadModal] =
    useState<boolean>(false);
  const [evaluation, setEvaluation] = useState<{
    documentId: string;
    result: ResourceDocumentEvaluationState;
    validity: string;
    suggestedValidity: string;
    noEvaluationExpiration: boolean;
    documentDuration: number;
  }>();
  const [uploadData, setUploadData] = useState<{
    id: string;
    documentName: string;
    files: FileList;
  }>();
  const [isDraggingOverRow, setIsDraggingOverRow] = useState(null);
  const [currentIndex, setCurrentIndex] = useState<number>();
  const [showDocumentDetail, setShowDocumentDetail] = useState<boolean>(false);

  const {token} = useAuth()

  const canAddFilePermission = useHasPermissions(permissions.addFile, PermissionCheck.All)

  const isEvaluationApprovalRequired =
    context.site?.options?.isEvaluationApprovalRequired;

  //Handle drag&drop start
  const toast = useToast();

  const handleDragIn = (index: string) => {
    setIsDraggingOverRow(index);
    if (!toast.isActive("uploadToast")) {
      toast({
        id: "uploadToast",
        description: t("dropDocument", { ns: "documents" })
      });
    }
  };

  const handleDrop = (
    document: SiteResourceDocument,
    e: React.DragEvent<HTMLTableRowElement>
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOverRow(null);

    if(canAddFilePermission) {
      if (e?.dataTransfer?.files[0]) {
        handleFileDrop(document, e.dataTransfer.files);
      }
    } else {
      toast({
        id: "uploadToast",
        description: t("noPermission", { ns: "documents" }),
        status: "error"
      });
    }
  };

  const handleFileDrop = (
    document: SiteResourceDocument,
    files: FileList | null
  ): void => {
    if (!files) {
      return;
    }
    const uploadData = {
      id: document?.id,
      documentName: document?.documentTypeName,
      files: files
    };
    toast.closeAll();
    setUploadData(uploadData);
    setIsDraggingOverRow(null);
    setShowConfirmUploadModal(true);
  };

  const openHistory = (document: SiteResourceDocument) => {
    setEnableGetDocLogs(false);
    setEnableGetFileLogs(true);

    return setShowDocumentInfoModal({
      requirementId: document?.id,
      documentId: document?.documentId,
      tab: 0
    });
  };

  const openDocumentDetail = async (document: SiteResourceDocument) => {
    setEnableGetDocLogs(false);
    setEnableGetFileLogs(false);
    await handleDocumentTypeId(document?.documentTypeId);
    refetchRenameModel();
    setShowDocumentDetail(true);
    setInitialCurrentIndex(document);
  };

  const handleConfirmationUpload = async () => {
    setShowConfirmUploadModal(false);
    if (uploadData) {
      await addFileToDocument(
        uploadData.id,
        processUploadedFiles(uploadData.files)
      );
      documentsRefetch();
      siteResourceRefetch && siteResourceRefetch();
    }
  };

  const handleAddFile = async (
    document: SiteResourceDocument,
    file: FileEntity,
    siteIds: string[]
  ) => {
    await addFileToDocument(document?.id, file, siteIds);
    setHookDocumentId(document?.id);
    documentsRefetch();
    siteResourceRefetch && siteResourceRefetch();
  };

  const handleDeleteFile = async (
    document: SiteResourceDocument,
    fileId: string,
    siteIds: string[],
    selectAll: boolean
  ) => {
    await deleteDocumentFile(document?.id, fileId, siteIds, selectAll);
    setHookDocumentId(document?.id);
    documentsRefetch();
    siteResourceRefetch && siteResourceRefetch();
  };

  const handleUpdateFile = (
    id: string,
    name: string,
    expiresAt: Date,
    siteIds: string[],
    selectAll: boolean
  ) => {
    updateFile(
      documents[currentIndex].documentId,
      id,
      [
        {
          id,
          name,
          expiresAt,
          updated: true
        }
      ] as Partial<FileEntity>[],
      siteIds,
      selectAll
    );
  };

  const setInitialCurrentIndex = (document: SiteResourceDocument) => {
    setCurrentIndex(documents.findIndex((d) => d?.id === document?.id));
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + documents.length) % documents.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % documents.length);
  };

  useEffect(() => {
    if (currentIndex != null) {
      setSiteResourceHookDocumentId(documents[currentIndex]?.id);
      setHookDocumentId(documents[currentIndex]?.id);
    }
  }, [currentIndex, documents]);
  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"start"}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        h="100%"
        width={
          deltaWidth ? `calc(100vw - ${deltaWidth})` : `calc(100vw - 180px)`
        }
        overflowX="auto"
        overflowY="hidden"
      >
        <InfiniteTable
          infiniteScroll={{
            dataLength: documents.length,
            hasNextPage: documentsHasNextPage,
            fetchNextPage: documentsFetchNextPage
          }}
          tableId={"site-resource-documents-table-" + requirementSubject}
          showEmptyText={documents?.length === 0}
          isLoading={isLoadingDocuments}
          emptyText={t("noResourceRequirements", { ns: "requirements" })}
        >
          <Thead>
            <Tr>
              <Th width={isTablet ? "300px" : undefined}>
                <TableColumnHeader
                  text={t("type")}
                  filter={{
                    isActive: !!filterDocuments["name"],
                    component: (
                      <TextInput
                        value={filterDocuments["name"] as string}
                        onChange={(value) =>
                          updateFilterDocuments("name", value)
                        }
                        placeholder={t("search", { ns: "common" })}
                      />
                    )
                  }}
                  sort={{
                    handler: (direction) =>
                      updateSortDocuments({ field: "name", direction }),
                    direction:
                      sortDocuments && sortDocuments.field === "name"
                        ? sortDocuments.direction
                        : null
                  }}
                />
              </Th>
              <Th
                width={
                  isWorkingSite
                    ? "180px"
                    : isTablet && isSubcontract
                      ? "250px"
                      : "370px"
                }
                textAlign="center"
              >
                {t("authorized")}
              </Th>
              {isEvaluationApprovalRequired &&
                documents[0]?.evaluationResult && (
                  <Th textAlign="center" width={"90px"} />
                )}
              <Th
                textAlign="center"
                width={isTablet && isWorkingSite ? "170px" : "240px"}
              >
                {t("actions")}
              </Th>
            </Tr>
          </Thead>

          <Tbody borderRadius="xl">
            {documents?.map((document) => {
              let showLastUploadedFile;
              if (
                document?.lastUploadedFileUri?.includes(".xlsx") ||
                document?.lastUploadedFileUri?.includes(".xls") ||
                document?.lastUploadedFileUri?.includes(".zip") ||
                document?.lastUploadedFileUri?.includes(".doc") ||
                document?.lastUploadedFileUri?.includes(".docx")
              ) {
                showLastUploadedFile = false;
              } else {
                showLastUploadedFile = true;
              }
              return (
                <Tr
                  key={document?.id}
                  style={{
                    backgroundColor:
                      isDraggingOverRow === document?.id
                        ? COLORS.lightBlue
                        : document?.isManual
                          ? COLORS.lightYellow
                          : "white"
                  }}
                  onDragEnter={() => handleDragIn(document?.id)}
                  onDrop={(e) => handleDrop(document, e)}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <Td
                    style={{
                      fontWeight: !document?.isOptional ? "700" : "400",
                      width: isTablet ? "300px" : "100%"
                    }}
                  >
                    <Flex alignItems={"center"}>
                      <Tooltip
                        label={document?.documentTypeDescription}
                        key={document?.id}
                        placement="bottom-start"
                        openDelay={500}
                      >
                        <span>
                          {document?.documentTypeName}
                          {!document?.isOptional && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </span>
                      </Tooltip>
                      {document?.lastUploadedFileUri && showLastUploadedFile && (
                        <Tooltip
                          label={t("lastUpdatedFile", { ns: "documents" })}
                          placement="bottom-start"
                        >
                          <span style={{ marginLeft: 10 }}>
                            <LuFileSearch2
                              color={COLORS.sikuroBlue}
                              cursor="pointer"
                              onClick={() =>
                                window.open(
                                  document?.lastUploadedFileUri,
                                  "_blank"
                                )
                              }
                            />
                          </span>
                        </Tooltip>
                      )}
                    </Flex>
                  </Td>
                  <Td
                    justifyContent="center"
                    alignItems="center"
                    width={
                      isWorkingSite
                        ? "180px"
                        : isTablet && isSubcontract
                          ? "250px"
                          : "370px"
                    }
                  >
                    <Flex
                      flexDirection={"row"}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      {isWorkingSite ? (
                        <Flex
                          flexDirection={"column"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={1}
                        >
                          {document?.evaluationResult ? (
                            <EvaluationButton
                              value={document?.evaluationResult}
                              evaluationType="document"
                            />
                          ) : (
                            <Tooltip
                              label={t("emptyRequirementEvaluation", {
                                ns: "requirements"
                              })}
                            >
                              <Flex
                                borderWidth="1px"
                                borderRadius={"5px"}
                                color={"black"}
                                padding={1}
                                fontSize={"12px"}
                                justifyContent={"center"}
                                width={"6rem"}
                              >
                                -
                              </Flex>
                            </Tooltip>
                          )}
                          {document?.evaluationExpiresAt && (
                            <Text
                              textAlign={"center"}
                              fontSize={"12px"}
                              width={"10rem"}
                            >
                              {t("validity", {
                                date: formatDateWithGraceDays(
                                  document?.evaluationExpiresAt,
                                  document?.graceDays
                                )
                              })}
                            </Text>
                          )}
                        </Flex>
                      ) : (
                        <RenderIf permissions={permissions.evaluate}>
                          <DocumentEvaluationToggle
                            document={document}
                            setEvaluation={setEvaluation}
                            evaluateDocument={evaluateDocument}
                            evaluation={evaluation}
                            permissions={permissions}
                            evaluateDocumentIsLoading={evaluateDocumentIsLoading}
                          />
                        </RenderIf>
                      )}
                      {isEvaluationApprovalRequired &&
                        document?.evaluationResult &&
                        document?.evaluationResult !==
                        ResourceDocumentEvaluationState.UNEVALUATED && (
                          <Box ml="20px">
                            <RenderIf permissions={permissions.evaluate}>
                              {document["isPendingEvaluation"] && (
                                <Tooltip
                                  label={t("needsApproval", { ns: "sites" })}
                                >
                                  <Icon fontSize="25px" mr={4}>
                                    <AiFillWarning color={COLORS.yellow} />
                                  </Icon>
                                </Tooltip>
                              )}
                              {!document["isPendingEvaluation"] &&
                                document?.evaluationResult && (
                                  <Tooltip
                                    label={t("approved", { ns: "sites" })}
                                  >
                                    <Icon fontSize="25px" mr={4}>
                                      <AiFillCheckCircle
                                        color={COLORS.sikuroBlue}
                                      />
                                    </Icon>
                                  </Tooltip>
                                )}
                            </RenderIf>
                          </Box>
                        )}
                      {isEvaluationApprovalRequired &&
                        (!document?.evaluationResult ||
                          document?.evaluationResult ===
                          ResourceDocumentEvaluationState.UNEVALUATED) && (
                          <Box ml="20px" marginRight={10} />
                        )}
                    </Flex>
                  </Td>

                  <Td width={isTablet && isWorkingSite ? "170px" : "240px"}>
                    <Flex gap={2} justifyContent="center" alignItems={"center"}>
                      <RenderIf permissions={permissions.chat}>
                        {showCommunications && (
                          <CommentButton
                            onClick={(comment) => {
                              if (comment) {
                                communicationProps.createResourceDocumentComment(
                                  `#[${document?.documentTypeName}](${document?.id}) ${comment}`
                                );
                              }
                            }}
                            comments={
                              communicationProps.resourceDocumentComments?.filter(
                                (c) => c.comment.includes(`(${document?.id})`)
                              ) ?? []
                            }
                            isLoading={communicationProps.isFetching}
                            taggableUsers={communicationProps?.taggableUsers}
                          />
                        )}
                      </RenderIf>

                      <HistoryButton onClick={() => openHistory(document)} />
                      <RenderIf>
                        <AddFileButton
                          onClick={() => openDocumentDetail(document)}
                          filesCount={document?.filesCount}
                        />
                      </RenderIf>
                      <RenderIf permissions={permissions.addFile}>
                        <Tooltip
                          label={
                            document?.template
                              ? t("downloadTemplate", { ns: "documents" })
                              : t("noUploadedTemplate", { ns: "documents" })
                          }
                          aria-label="DownloadTemplate"
                        >
                          <span>
                            <Link
                              isExternal
                              _hover={
                                document?.template
                                  ? { cursor: "pointer" }
                                  : { cursor: "unset" }
                              }
                              as="button"
                              onClick={() => {
                                if(document?.id &&  document?.template?.id){
                                getRequirementTemplateUrl(document.id, document?.template.id, (url) => downloadFileFromURL(url, token.access_token ))
                              }}}
                            >
                              <MdOutlineDocumentScanner
                                color={
                                  document?.template
                                    ? COLORS.sikuroBlue
                                    : "#767676"
                                }
                                size={22}
                              />
                            </Link>
                          </span>
                        </Tooltip>
                      </RenderIf>
                      {onDelete && (
                        <RenderIf permissions={permissions.delete}>
                          <DeleteButton
                            onClick={(e) => {
                              e.stopPropagation();
                              onDelete?.(document?.id, requirementSubject);
                            }}
                          />
                        </RenderIf>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </InfiniteTable>
      </Flex>
      {showDocumentDetail && (
        <DocumentDetailModal
          resourceId={resourceId}
          deleteIsLoading={deleteIsLoading}
          document={documents[currentIndex]}
          fileIsLoading={documentFilesFetching}
          documentsFetching={isFetchingDocuments}
          isOpen={showDocumentDetail}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          onClose={() => {
            setSiteResourceHookDocumentId(undefined);
            setHookDocumentId(undefined);
            setShowDocumentDetail(false);
            setCurrentIndex(null);
          }}
          isReadOnly={isReadOnly}
          files={documentFiles}
          canAddFile={canAddFile}
          addFile={(file, siteIds) =>
            handleAddFile(documents[currentIndex], file, siteIds)
          }
          addFileIsLoading={isAddingFile}
          deleteFile={(fileId, siteIds, selectAll) =>
            handleDeleteFile(
              documents[currentIndex],
              fileId,
              siteIds,
              selectAll
            )
          }
          editFile={(id, name, expiresAt, siteIds, selectAll) =>
            handleUpdateFile(id, name, expiresAt, siteIds, selectAll)
          }
          evaluateDocument={(
            documentId,
            result,
            validity,
            noEvaluationExpiration
          ) => {
            evaluateDocument({
              documentId,
              result,
              validity: validity?.toString(),
              noEvaluationExpiration
            });
          }}
          onPrev={handlePrev}
          onNext={handleNext}
          renameModels={renameModel?.download}
          communicationProps={communicationProps}
          type="requirements"
          downloader={downloader}
          siteGeneralDocument={false}
          requirementSubject={requirementSubject}
          isWorkingSite={isWorkingSite}
          docDetailPermissions={permissions}
          resourceCategory={resourceCategory}
          showCommunications={showCommunications}
        />
      )}

      {showDocumentInfoModal && (
        <DocumentInfoModal
          onClose={() => {
            setShowDocumentInfoModal(false);
            setSiteResourceHookDocumentId(undefined);
            setHookDocumentId(undefined);
          }}
          tab={showDocumentInfoModal.tab ?? 0}
          changeTab={(tab) => {
            setShowDocumentInfoModal({ ...showDocumentInfoModal, tab });
          }}
          getDocumentEvaluations={() => {
            setSiteResourceHookDocumentId(showDocumentInfoModal.requirementId);
          }}
          getDocumentLogs={() => (
            setEnableGetFileLogs(true),
              setHookDocumentId(showDocumentInfoModal.documentId)
          )}
          documentFileLogs={documentFileLogs}
          documentEvaluations={documentEvaluations}
          filterDocumentLog={filterDocumentLog}
          updateFilterDocumentLog={updateFilterDocumentLog}
          sortDocumentLog={sortDocumentLog}
          setSortDocumentLog={setSortDocumentLog}
          filterDocumentEvaluations={filterDocumentEvaluations}
          updateFilterDocumentEvaluations={updateFilterDocumentEvaluations}
          sortDocumentEvaluations={sortDocumentEvaluations}
          updateSortDocumentEvaluations={updateSortDocumentEvaluations}
          documentLogsFetching={documentLogsFetching}
          documentEvaluationsFetching={documentEvaluationsFetching}
        />
      )}
      {successMsg && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(successMsg, { ns: "errors" })}
          variant="info"
          onClose={() => {
            setSuccessMsg(null);
          }}
        />
      )}
      {errorMsg && !showDocumentDetail && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(errorMsg, { ns: "errors" })}
          variant="info"
          onClose={() => {
            setErrorMsg(null);
          }}
        />
      )}
      {showConfirmUploadeModal && (
        <ConfirmAlert
          title={t("warning", { ns: "common" })}
          message={t("dropDocumentMessage", {
            documentName: uploadData.documentName,
            ns: "documents"
          })}
          variant="warning"
          onCancel={() => setShowConfirmUploadModal(false)}
          onConfirm={() => {
            handleConfirmationUpload();
            setShowConfirmUploadModal(false);
          }}
        />
      )}
      {showManageTaskWithAi && (
        <ManageTaskWithAiModal
          title={t("uploadWithAi", { ns: "documents" })}
          onClose={() => {
            documentsRefetch();
            setShowManageTaskWIthAi(false);
          }}
          isPropagable={false}
          aiTaskSectionCode={aiTaskSectionCode}
          aiTasks={documentsAiHook.aiTasks}
          createAiTask={documentsAiHook.createAiTask}
          aiTaskDetail={documentsAiHook.aiTaskDetail}
          aiTaskDetailFetching={documentsAiHook.aiTaskDetailFetching}
          setSelectedAiTaskId={documentsAiHook.setAiTaskId}
          aiTasksHasNextPage={documentsAiHook.aiTasksHasNextPage}
          aiTasksFetchNextPage={documentsAiHook.aiTasksFetchNextPage}
          aiTasksFetching={documentsAiHook.aiTasksFetching}
          onDeleteTask={documentsAiHook.deleteAiTask}
          onConfirmTask={documentsAiHook.confirmAiTask}
          onConfirmTaskIsLoading={documentsAiHook.confirmAitaskIsLoading}
          documentTypesForAi={documentsAiHook.documentTypesForAi}
          setEnableDocumentTypesForAiQuery={
            documentsAiHook.setEnableDocumentTypesForAiQuery
          }
          sitesWhereToPropagate={documentsAiHook.sitesForAiTask}
        />
      )}
    </>
  );
};

export default SiteResourceDocuments;
