import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  Spacer,
  AccordionPanel,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionIcon,
  Tooltip,
  Divider,
  Tag,
} from "@chakra-ui/react";
import Supplier from "../../../../domain/entities/supplier";
import { useTranslation } from "react-i18next";
import StateTag from "../../Common/StateTag";
import { SupplierEvaluationState } from "../../../../domain/entities/supplierEvaluationState.enum";
import { SupplierStatus } from "../../../../domain/entities/supplierStatus.enum";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "../../../screens/Common/Alert";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { HiOutlineArchiveBoxXMark } from "react-icons/hi2";
import { COLORS } from "../../../assets/theme/colors";

type SupplierListProps = {
  suppliers: Supplier[];
};

const SiteSuppliersNestedView: React.FC<SupplierListProps> = ({
  suppliers,
}) => {
  const { t } = useTranslation("supplier");
  const { siteId } = useParams();
  const navigate = useNavigate();
  const [showNavigationAlert, setShowNavigationAlert] = useState<
    [boolean, string]
  >([false, ""]);

  const navigateToSupplierDetail = (supplier: Supplier) => {
    navigate(`/sites/${siteId}/suppliers/${supplier?.company?.id}/`);
  };

  const renderSupplier = (supplier: Supplier, invitedBy?: string) => (
    <Accordion
      key={supplier?.company?.id}
      marginTop={0}
      width={"100%"}
      allowToggle
      defaultIndex={[0]}
      borderRadius={5}
    >
      <AccordionItem
        marginTop={0}
        paddingBottom={0}
        borderRadius={5}
        border={"none"}
      >
        <AccordionButton
          backgroundColor={supplier.archived ? COLORS.lightRed : "gray.100"}
          borderRadius={5}
        >
          <Tooltip
            label={t("navigateSupplierDetail", { ns: "supplier" })}
            isDisabled={supplier.archived}
          >
            <Flex
              borderRadius={5}
              flexDirection={"column"}
              onClick={() => {
                let alertText;
                if (supplier.status === SupplierStatus.PENDING) {
                  alertText = "navigationBlocked";
                } else if (supplier.archived) {
                  alertText = "supplierNavigationBlocked";
                }
                supplier.status !== SupplierStatus.PENDING && !supplier.archived
                  ? navigateToSupplierDetail(supplier)
                  : setShowNavigationAlert([true, alertText]);
              }}
              sx={{
                ":hover": {
                  backgroundColor: "gray.300",
                },
              }}
            >
              <Flex flexDirection={"row"}>
                <Text fontWeight="bold">{supplier?.company?.name}</Text>
                <StateTag
                  marginLeft="2"
                  value={
                    supplier?.evaluationResult ??
                    SupplierEvaluationState.UNEVALUATED
                  }
                  type="supplierEvaluation"
                />
                <StateTag
                  marginLeft="2"
                  value={supplier?.status}
                  type="supplierStatus"
                />
              </Flex>
              <Flex>
                <Text fontSize={14}>
                  {t("vatCode", { ns: "supplier" })}: {supplier?.company?.vat}{" "}
                  {"-"}
                </Text>
                {invitedBy && (
                  <Text fontSize={14} marginLeft={2}>
                    {t("invitedBy", { ns: "supplier" })} {invitedBy} {"-"}
                  </Text>
                )}
                <Text fontSize={14} marginBottom={2} marginLeft={2}>
                  {t("columns.subscribeDate", { ns: "supplier" })} :{" "}
                  {formatDateBasedOnLanguage(supplier?.subscribeDate, true)}
                </Text>
              </Flex>
            </Flex>
          </Tooltip>
          <Spacer />
          {supplier.subContractors && supplier?.subContractors?.length > 0 && (
            <AccordionIcon />
          )}
        </AccordionButton>

        {supplier.subContractors && supplier?.subContractors?.length > 0 && (
          <AccordionPanel
            pb={0}
            marginRight={0}
            paddingRight={0}
            border={"none"}
            width={"100%"}
          >
            <Flex flexDirection={"row"} width={"100%"}>
              {supplier.subContractors &&
                supplier?.subContractors?.length > 0 && (
                  <Flex
                    width={"100%"}
                    flexDirection={"column"}
                    rowGap={2}
                    pl={4}
                  >
                    {supplier?.subContractors?.map((sub) =>
                      renderSupplier(sub, supplier.company.name)
                    )}
                  </Flex>
                )}
            </Flex>
          </AccordionPanel>
        )}
      </AccordionItem>
    </Accordion>
  );

  return (
    <Box width={"100%"}>
      {suppliers.map((supplier) => {
        return (
          <Flex
            key={supplier?.company?.id}
            marginTop={5}
            flexDirection={"column"}
          >
            {renderSupplier(supplier)}
            <Divider marginTop={5} />
          </Flex>
        );
      })}
      {showNavigationAlert[0] && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(showNavigationAlert[1], { ns: "supplier" })}
          variant="info"
          onClose={() => {
            setShowNavigationAlert([false, ""]);
          }}
        />
      )}
    </Box>
  );
};

export default SiteSuppliersNestedView;
