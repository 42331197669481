import SignIn from "./SignIn";
import SignUpUser from "./SignUpUser";
import SignUpCompany from "./SignUpCompany";
import ZStack from "../../components/Common/ZStack";
import OnboardingSelector from "./OnboardingSelector";
import OnboardingBackground from "./OnboardingBackground";
import OnboardingBackgroundText from "./OnboardingBackgroundText";
import { AbsoluteCenter, Card, Collapse, Box } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import OnboardingRequestPasswordReset from "./OnboardingRequestPasswordReset";
import { useMediaQuery } from "@chakra-ui/react";
import useOnboardingViewModel from "../../hooks/Onboarding/useOnboardingViewModel";
import LoadingView from "../Common/LoadingView";
import { useCallback, useEffect, useState } from "react";
import { Alert } from "../Common/Alert";
import { useTranslation } from "react-i18next";

enum Screen {
  Selector = "selector",
  SignUpCompany = "sign-up-company",
  SignUpUser = "sign-up-user",
  SignIn = "sign-in",
  RequestPasswordReset = "request-password-reset",
}

const OnboardingView = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    screen: Screen.SignIn,
  });
  const [showLoadingView, setShowLoadingView] = useState<boolean>(false)
  const selectedScreen = searchParams.get("screen") as Screen;
  const token = searchParams.get("token"); // invite token
  const company = searchParams.get("company");
  const site = searchParams.get("site");
  const email = searchParams.get("email");
  const owner = searchParams.get("owner");

  const { t } = useTranslation("errors");

  const onCancel = () => setSearchParams({ screen: Screen.Selector });
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const {
    userAlreadyRegistered,
    userAlreadyRegisteredLoading,
    activationError,
    setActivationError,
    isUserEmailAvailable,
  } = useOnboardingViewModel();

  const setScreenParams = useCallback((screen: Screen, additionalParams = {}) => {
    const params = {
      screen,
      token,
      ...(email ? { email: email } : {}),
      ...(company ? { company: company } : {}),
      ...(site ? { site: site } : {}),
      ...(owner ? { owner: owner } : {}),
      ...additionalParams,
    };
    setSearchParams(params);
  }, [token, email, company, site, owner, setSearchParams]);

  const handleUserAvailability = useCallback(
    async (activateKey: string, isSignUpCompany: Screen, isSignIn: Screen) => {
      setShowLoadingView(true);
      const isAvailable = await isUserEmailAvailable();
      setShowLoadingView(false);
      const screen = isAvailable ? isSignUpCompany : isSignIn;
      setScreenParams(screen, { [activateKey]: activateKey });
    },
    [isUserEmailAvailable, setScreenParams]
  );

  useEffect(() => {
    const handleParamsAndRedirect = async () => {
      if (window.location.search.includes("activateUser")) {
        setShowLoadingView(userAlreadyRegisteredLoading);
        const screen = userAlreadyRegistered?.already_registered ? Screen.SignIn : Screen.SignUpUser;
        setScreenParams(screen, { activateUser: 'activateUser' });
      } else if (window.location.search.includes("activateSupplier")) {
        await  handleUserAvailability('activateSupplier', Screen.SignUpCompany, Screen.SignIn);
      } else if (window.location.search.includes("activateSubcontractor")) {
        await handleUserAvailability('activateSubcontractor', Screen.SignUpCompany, Screen.SignIn);
      } else if (window.location.search.includes("confirmSubcontractor")) {
        await handleUserAvailability('confirmSubcontractor', Screen.SignUpCompany, Screen.SignIn);
      }
    };
    handleParamsAndRedirect();
  }, [token, company, userAlreadyRegistered, setSearchParams]);

  return (
    <ZStack>
      <OnboardingBackgroundText />
      <OnboardingBackground />

      <div style={{ width: "100vw", height: "100vh" }}>
        <AbsoluteCenter>
          <Card borderRadius={16} width={isMobile ? "100%" : 512}>
            {showLoadingView ? (
              <Box p={4}>
                <LoadingView />
              </Box>
            ) : (
              <>
                <Collapse
                  in={selectedScreen === Screen.Selector}
                  animateOpacity
                >
                  <OnboardingSelector
                    onSelectSignUpCompany={() =>
                      setSearchParams({ screen: Screen.SignUpCompany })
                    }
                    onSelectSignUpUser={() =>
                      setSearchParams({ screen: Screen.SignUpUser })
                    }
                    onSelectSignIn={() =>
                      setSearchParams({ screen: Screen.SignIn })
                    }
                  />
                </Collapse>
                <Collapse
                  in={selectedScreen === Screen.SignUpCompany}
                  animateOpacity
                >
                  {selectedScreen === Screen.SignUpCompany && (
                    <SignUpCompany onCancel={onCancel} />
                  )}
                </Collapse>
                <Collapse
                  in={selectedScreen === Screen.SignUpUser}
                  animateOpacity
                >
                  {selectedScreen === Screen.SignUpUser && (
                    <SignUpUser onCancel={onCancel} />
                  )}
                </Collapse>
                <Collapse in={selectedScreen === Screen.SignIn} animateOpacity>
                  {selectedScreen === Screen.SignIn && (
                    <SignIn
                      onCancel={onCancel}
                      onSelectRequestPasswordReset={() =>
                        setSearchParams({ screen: Screen.RequestPasswordReset })
                      }
                    />
                  )}
                </Collapse>
                <Collapse
                  in={selectedScreen === Screen.RequestPasswordReset}
                  animateOpacity
                >
                  {selectedScreen === Screen.RequestPasswordReset && (
                    <OnboardingRequestPasswordReset
                      onCancel={() =>
                        setSearchParams({ screen: Screen.SignIn })
                      }
                    />
                  )}
                </Collapse>
              </>
            )}
          </Card>
        </AbsoluteCenter>
      </div>
      {activationError && (
        <Alert
          title={t(`errorUserInvitation`)}
          message={t(activationError)}
          onClose={() => setActivationError("")}
          variant="warning"
        />
      )}
    </ZStack>
  );
};

export default OnboardingView;
